body {
    /*margin: 0;*/
    /*padding: 0;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
    /*color:#666;*/
    font-size: 1rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.bottom-bordered {
    border-bottom: 1px solid #f0f0f0;
    padding: 5px;
    text-align: left;
}

.btn-remove{
    float: right;
}

.bold{
    font-weight: bold;
}

.titles{
    font-style: normal !important;
    font-size: 17px;
    color: #222;
    padding-bottom: 5px;
    text-align: left;
    font-weight: 500;
}

table  th {
    padding: .75rem;
    vertical-align: top;
}


.table-reference {
    /*background-color: #f9f9f9;*/
    padding: 20px 0 25px;
    border: none !important;
    border-top: 0px solid #fff;
    margin: 10px 0;
    border-top: 0 !important;

}

.table-reference td {
    border-right: 1px solid #eee;
    border-top: 0;
}

.title{
    font-weight: bold;
}

.ant-row.ant-form-item {
    margin: auto;
}


.logo {
    max-width: 161px;
    margin: auto;

}

.row-logo {
    margin-left: 28px;
    float: left;
}

.menu-header {
    float: left;
}


@media only screen and (max-width: 768px) {
    .menu-header {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .sider {
        display: none !important;
    }
}

.back {
    padding: 20px;
}

.right {
    float: right;
}

.center {
    float: none;
    text-align: center;
}


.add-button {
    padding: 10px;
    float: right;
}

.ant-menu-horizontal {
    border-bottom: 0 !important;
}

.ant-divider.ant-divider-horizontal.ant-divider-with-text {
    margin: 27px auto;
}

.form {
    max-width: 900px;
    margin: 20px auto auto;
}


.login-form {
    max-width: 300px;
    margin: auto;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.ant-alert.ant-alert-error {
    margin: 5px;
}

.error-list {
    max-width: 800px;
    margin: auto;
    padding: 17px 0;
    list-style-type: none;
}

ul.error-list li {
    color: red;
}


.table-process tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff !important;
}

.table-process tr.drop-over-upward td {
    border-top: 2px dashed #1890ff !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.variation td, th {
    /*background: #fff;*/
}

.ant-table-wrapper.variation table th {
    /*background-color: white !important;*/
    font-weight: 100;
    border: none;
}


tr.ant-table-row.completed {
    /*background: #dcfadc;*/
}

li.ant-timeline-item {
    padding: 0;
}

.ant-timeline-item-last > .ant-timeline-item-content {
    min-height: 9px;
}


.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
}

.editable-row:hover {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
    cursor: pointer;
}

.login-logo-form .button {
    width: 100% !important;
}

.ant-radio-wrapper {
    display: list-item !important;
}


.link {
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    color: #c00;
}

.link:hover, .link:focus {
    text-decoration: underline;
}

.ant-table-fixed-right {
    width: 120px;
}


.tool-button {
    border: none;
    color: #FFF;
    cursor: pointer;
    font-size: 20px;
    padding: 6px;
    margin: auto;
    border-bottom: solid 1px #fff;
}


.ant-select-dropdown {
    z-index: 99999999999999 !important;
}


.variations .ant-table-footer {
    text-align: right;
}

.quote-floating-bar {
    position: fixed;
    top: 100px;
    right: 0;
    width: 35px;
    z-index: 99;
    /*background-color: #1890ff*/
}

.ant-row.quote-line-item {
    margin: 5px 0;
}

.ant-row.quote-status {
    margin: 20px 0;
}

.ant-row.quote-additional-cost {
    margin: 20px 0;
}

.ant-row.approx-shipping-cost span {
    font-style: italic;
    font-size: 15px !important;
}

.quote-totals .title, .value {
    font-size: 17px;
    float: right;
    margin-right: 13px;
}

.components-table-drag-sorting tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
}

.components-table-drag-sorting tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
}

/*trix-toolbar {*/
/*    padding: 1rem 1rem 0.5rem;*/
/*}*/
/*trix-editor {*/
/*    height: 100%;*/
/*}*/
/*trix-editor ul {*/
/*    padding-left: 1rem;*/
/*    list-style: disc;*/
/*}*/

/*.trix-button-row {*/
/*    flex-wrap: wrap !important;*/
/*}*/

/*trix-toolbar .trix-button-group:not(:first-child) {*/
/*    margin-left: 0 !important;*/
/*}*/


.address-title {
    font-size: .9286rem;
    color: #9197a3;
    padding: 7px 0 2px;
    text-transform: uppercase;
}


span.ant-comment-content-author-time {
    color: #000;
}

.rfq-view label {
    font-weight: bold;
}

table.rfq-table-bordered td {
    border: 1px solid #dee2e6;
    padding: 4px;
}

table.rfq-table-bordered th {
    border: 1px solid #dee2e6;
    padding: 4px;
}

.ant-divider.ant-divider-vertical {
    /*background: #000;*/
}

.box-form  label {
    font-weight: 600;
}

.ant-legacy-form {
    width: 100%;
}
.ant-legacy-form-item {
    margin-bottom: 0 !important;
}


.collapses-container {
    ul {
        list-style: inside;
        padding-left: 20px;

    }

    ol {
        list-style: inside;
        padding-left: 20px;
        list-style-type: decimal;
    }

    blockquote,
    figure {
        margin-left: 1rem;
        padding-left: 0.25rem;
        border-left: 1px solid black;
    }
}
