.d-block {
    display: block;
}

.d-flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.flex-grow-1 {
    flex-grow: 1;
}

.font-size-sm {
    font-size: 0.85rem;
}

.quote-product-card {
    border: 1px solid #e2e8f0;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
}

.quote-product-card .quickturn-notification {
    padding: 0.5rem;
    margin: 1rem 1rem 0;
    color: darkgreen;
}

.quote-product-card .quickturn-notification a {
    margin: 0;
    color: darkgreen;
    text-decoration: underline;
    font-weight: 600;
}

.quote-product-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
}

.quote-product-header .ant-tag {
    margin-top: 4px;
    line-height: 20px;
}

.quote-product-header .product-card-actions {
    display: flex;
    flex-direction: row;
}

.quote-product-body {
    display: flex;
    flex-wrap: nowrap;
    /* border-top: 1px solid #e2e8f0; */
    border-top: none;
}

.quote-product-preview {
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 200px;
    /* border-right: 1px solid #e2e8f0; */
    border-right: none;
}

.quote-product-card .product-summary {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    align-items: flex-end;
}

.product-collapsed-container {
    padding: 0 1rem 0.5rem 1rem;
}

.form-master-product .ant-upload {
    width: 100% !important;
}

.quote-product-details {
    flex-grow: 1;
}

.quote-product-variant {
    border: 1px solid rgba(204, 204, 204, 0.452);
    border-radius: 0.25rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
    /* box-shadow: 1px 1px 5px rgba(204, 204, 204, 0.475); */
}

.quote-product-variant-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 0 1rem;
    height: 2rem;
    /* border-bottom: 1px solid #e2e8f0; */
    border-bottom: none;
    background-color: #fff;
    /* background-color: #fff9d78f; */
    font-weight: 600;
}

.remove-variant-btn {
    /* height: 24px; */
    /* width: 24px; */
}

.quote-product-requirements {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
}

.quote-no-quotable-message {
    padding: 1rem;
}

.quote-product-variant-spec {
    display: block;
    margin: 0;
}

/* .quote-product-variant-spec .ant-select-selector {
    height: 2rem !important;
}

.quote-product-variant-spec .ant-select-selector .ant-select-selection-item {
    line-height: 2rem !important;
} */

.quote-product-variant-spec .variant-spec-dropdown-wrapper {
    margin-bottom: 2px;
}

.quote-product-variant-spec .ant-form-item {
    margin-bottom: 0;
    min-width: 100%;
}

.quote-product-variant-spec .variant-spec-dropdown-wrapper .ant-form-item-row {
    width: 100%;
    margin: 0;
}

.quote-product-variant-spec label {
    min-width: 110px;
}

.quote-product-requirements .quote-product-notes {
    flex-grow: 1;
}

.quote-product-variant-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
}

.quote-product-variant-qty-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem 0;
}

.price-render {
    padding-left: 2rem;
}

.manual-review-required-message {
    background-color: #eff8fb !important;
    border-color: #a3cadb !important;
    /* max-width: 360px; */
}

.manual-review-required-message .ant-alert-message {
    color: rgb(48, 64, 80) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.manual-review-required-message .ant-alert-description {
    color: rgb(48, 64, 80) !important;
    font-size: 13px !important;
    line-height: 1.5;
}

.manual-review-required-message .anticon {
    color: #0099fe !important;
    /* font-size: 14px !important;
    margin-right: 0.5rem !important; */
}

.quantity-selector .button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.quantity-selector .ant-input-number-hover,
.quantity-selector .ant-input-number-focused {
    box-shadow: none !important;
}

.quantity-selector .button-group .ant-input-number {
    width: fit-content !important;
    border-radius: 0 !important;
}

.quantity-selector .button-group .ant-input-number:hover,
.quantity-selector .button-group .ant-input-number:focus {
    box-shadow: none !important;
}

.quantity-selector .button-group input {
    border-radius: 0 !important;
    text-align: center;
}

.quantity-selector .button-group input:focus {
    box-shadow: none !important;
}

.quantity-selector .button-group .ant-input-number,
.quantity-selector .button-group .ant-input-number-sm {
    width: 4rem !important;
}

.quantity-selector .button-group .minus-btn,
.quantity-selector .button-group .plus-btn {
    min-width: 32px !important;
}

.quantity-selector .button-group .minus-btn .anticon,
.quantity-selector .button-group .plus-btn .anticon {
    font-size: 16px !important;
}

.quantity-selector .button-group .minus-btn {
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.quantity-selector .button-group .plus-btn {
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.delivery-options {
    width: 100%;
}

.delivery-options .ant-card {
    margin-bottom: 0;
}

.delivery-options .ant-card-body {
    padding: 1rem;
}

.delivery-options .active {
    box-shadow: 2px 2px 10px #dedede;
    border: 1px solid #c00;
}

.part-preview-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.thumbnail-3d-file {
    display: block;
    width: 100%;
    -webkit-user-drag: none;
    cursor: pointer;
}

.thumbnail-2d-file {
    display: block;
    width: 100%;
    filter: blur(0.3px);
    -webkit-user-drag: none;
    cursor: pointer;
}

.quote-product-variant-qty-price {
    flex-direction: column;
}

.quote-product-variant-qty-price .product-price-unit {
    font-size: 1rem;
    font-weight: 400;
    padding-left: 0.5rem;
}

.quote-product-variant-qty-price .product-price-total {
    font-size: 1.5rem;
    font-weight: 500;
    padding-left: 0.5rem;
}


.richtexteditor {

    ul {
        list-style: inside;
        padding-left: 20px;

    }

    ol {
        list-style: inside;
        padding-left: 20px;
        ;
        list-style-type: decimal;
    }

    a {
        color: #CC0000;
    }

    blockquote,
    figure {
        margin-left: 1rem;
        padding-left: 0.25rem;
        border-left: 1px solid black;
    }

    h1 {
        font-size: 30px;
        margin-bottom: .5rem;
        font-family: inherit;
        line-height: 1.5;
        color: #232427;
        font-weight: bold;
    }


    pre {
        display: block;
        font-family: monospace;
        white-space: pre;
    }
}
