.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 1800px;
  max-width: 100%;
}

/* @media (max-width: 576px) {
  .container {
    margin-left: 1rem;
    margin-right: 1rem;
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .container {
    margin-left: 3rem;
    margin-right: 3rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
} */


.descriptions-antd {

  .ant-descriptions-header{
    margin-bottom: 5px;
  }

  .ant-descriptions-item{
    padding-bottom: 0px;

  }

  .ant-descriptions-item-label{
    font-weight: bold;
  }
}