
.redaction-tool {
  display: flex;
  flex-direction: row;
  width: 98vw;
  height: 100vh;
}

.side-left {
  width: 75vw;
  height: 100%;

}

.side-right {
  width: 22vw;
  height: 100%;
  .part-files-wrapper {
    overflow-y: auto;
    height: 95%;
  }
  .bottom-actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5%;
  }
}

.header-wrapper {
  width: 100%;
  height: 28%;
}

.body-wrapper {
  width: 100%;
  height: 75%;
}

.profiles-wrapper{
  display: block;
  width: 100%;
  height: 350px;
  overflow-x: auto;
}

.pdf-area-wrapper {
  width: 100%;
  height: 100%;

  .container {
    max-width: 100%;
    width: 100%;
    height: 100%;

    .header {
      width: 100%;
      height: 12%;
    }

    .app-wrapper {
      height: 88%;
      overflow-x: hidden;
      overflow-y: auto;
      //display: flex;
      //flex-direction: row;
      //align-items: center;
      //justify-content: center;
    }
  }
}
.tolerances-wrapper{
  height: 110px;
}


.react-pdf__Page__canvas {
  //margin: 0 auto;
}

.react-pdf__Page__textContent {
  visibility: hidden;
}

@media only screen and (min-width: 1440px) {
  .header-wrapper {
    width: 100%;
    height: 23%;
  }

  .body-wrapper {
    width: 100%;
    height: 77%;
  }
}


@media only screen and (min-width: 1920px) {
  .header-wrapper {
    width: 100%;
    height: 18%;
  }

  .body-wrapper {
    width: 100%;
    height: 82%;
  }
}

// 2K Override dimensions

@media only screen and (min-width: 2560px) {

  .header-wrapper {
    width: 100%;
    height: 12%;
  }

  .body-wrapper {
    width: 100%;
    height: 88%;
  }

  .pdf-area-wrapper {
    height: 100%;

    .container {
      height: 100%;

      .header {
        height: 6%;
      }

      .app-wrapper {
        height: 94%;
      }
    }
  }

  .react-pdf__Page {
    height: 70vh;
  }

}



@primary-color: #c00;