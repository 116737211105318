trix-toolbar {
    padding: 1rem 1rem 0.5rem;
}
trix-editor {
    height: 100%;
    background-color: white;
}
trix-editor ul {
    padding-left: 1rem;
    list-style: disc;
}

.trix-button-row {
    flex-wrap: wrap !important;
}

trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 0 !important;
}

/* .react-trix-suggestions { */
/*     z-index: 999 !important; */
/* } */

blockquote {
    margin-left: 1rem;
    padding-left: 0.25rem;
    border-left: 1px solid black;
}

